.App {
  text-align: center;
}

.whatsapp {
  position: fixed;
  bottom: 2px;
  right: 2px;
}

.whatsapp img {
  width: 60px;
}