body {
    font-family: 'Roboto Condensed', sans-serif;
    background-color: #ffe77aff;
}

#popup_div {
    position: fixed;
    align-items: center;
    height: 100%;
    width: 100%;

}

#cross_button {
    background: none;
    border: none;
    font-weight: 700;
    font-size: 40px;
    position: fixed;
    right: 400px;
}

#popup_div_close {
    display: none;
}

#popup_div img {
   
    width: 500px;
    height: 500px;
}

#home_div {
    margin-top: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    overflow: hidden;
}

marquee {
 margin-top: 55px;
 background-color: #2c5f2dff;
 color: #f5f5f5;
}

#new_div {
    font-family: 'Roboto Condensed', sans-serif;
    overflow: hidden;
    margin-bottom: 10px;
}

label {
    text-align: left;
}

#branding {
    color: #f5f5f5;
    bottom: 0;
    right: 0;
}

#branding a{
    color: #f5f5f5;
}

h4 {
    margin-top: -40px;
    margin-bottom: 0px;
}

.brand_div {
    margin-top: 25px;
    margin-bottom: 35px;
}

.brand_heading {
    text-align: center;
    font-weight: bold;
}

.map_image {
    width: 520px;
}

#heading {
    text-align: left;
    color: #2c5f2dff;
    font-weight: bold;
}

.sub_heading {
    text-align: left;
    font-weight: bold;
}


.description {
    text-align: justify;
}

.content {
    margin-top: 15px;
}

.div_left {
    display: block;
}

.left_div_list {
    width: 40%;
}
.left_div_pic {
    width: 60%;
}

.right_div_list {
    width: 40%;
}
.right_div_pic {
    width: 60%;
}

#product_list {
   text-align: left;
   line-height: 25px;
   text-transform: uppercase;
}

.list {
    background-color: #2c5f2dff;
    color: white;
    font-size: 15px;
}

a{
    text-decoration: none;
    color: black;
}

a:hover{
    text-decoration: none;
    color: black;
}

.brand_image{
    width: 70px;
    margin-top: 15px;
 }
/* 
.brand_image_div {
    margin-top: 20px;
}  */

.disclaimer {
    margin-top: 20px;
    color: gray;
    text-align: center;
}

.footer_images {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.footer_images img {
    width: 80px;
    margin: 5px;
}



/* for mobile */

@media only screen and (width < 768px) {
    .footer_images img {
        width: 50px;
    }

    #popup_div img {
        width: 250px;
        height: 250px;
    }

    #popup_div{
        align-items: center;
        vertical-align: middle;
        top: 30%;
    }

    #cross_button {
        background: none;
        border: none;
        font-weight: 700;
        font-size: 40px;
        position: fixed;
        right: 17vw;
    }

    .disclaimer{
        font-size: 10px;
    }

    .brand_image{
       width: 50px;
    }

    #home_div {
        padding-left: 5px;
        padding-right: 5px;
    }
    #new_div {
        padding-left: 5px;
        padding-right: 5px;
    }

    #heading-company_profile {
        font-size: 1.5rem;
        letter-spacing: 0.7px;
    }

    .description {
        font-size: 10px;
    }

    .sub_heading {
        font-size: 15px;
    }

    .brand_heading {
        font-size: 15px;
    }

    .map_image {
        margin-top: 15px;
        width: 170px;
        height: 120px;
    }

    .products {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .products img {
        width: 90px;
    }

    /* .products .names {
        background-color: #2c5f2dff;
    } */

    h4 {
        font-size: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    /* for single product image in div */

    .product {
        margin-top: 10px;
        border: 1px solid #f5f5f5;
    }

    .product img {
        width: 60px;
    }

    .product .names {
        font-size: 10px;
    }

    .product:hover {
        border: 1px solid #2c5f2dff;
    }

    .product:hover .names{
        background-color: #2c5f2dff;
        color: white;
    }

    #product_list {
        line-height: 38px;
        font-size: 11px;
    }

    .left_div_list {
        width: 43%;
    }
    .left_div_pic {
        width: 58%;
    }
    
    .right_div_list {
        width: 42%;
    }

    .right_div_pic {
        width: 57%;
    }

    #branding {
        font-size: 10px;
    }
}

/* for computer */

@media only screen and (width >= 768px) {
    #home_div {
        padding-left: 20px;
        padding-right: 20px;
    }
    #new_div {
        padding-left: 20px;
        padding-right: 20px;
    }

    #heading-company_profile {
        font-size: 2rem;
        letter-spacing: 0.7px;
    }

    .sub_heading {
        font-size: 25px;
    }

    .brand_heading {
        font-size: 25px;
    }

    .products {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .products img {
        width: 150px;
    }

    .products .names {
        font-weight: bold;
    }

    /* for single product image in div */
    .product {
        border: 1px solid #f5f5f5;
        margin: 5px;
    }
    .product img {
        width: 80px;
    }

    .product:hover{
        border: 1px solid #2c5f2dff;
    }

    .product:hover .names{
        background-color: #2c5f2dff;
        color: white;
    }
}

